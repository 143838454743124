var DatetimeSelectWithDaylightSavingDisambiguation = {
  isAmbiguousFallBackTime: function(time) {
    if (!dateFns.isValid(time)) { return false; }
    if(dateFns.format(time, 'H') != '1') { return false; }

    const earlier = dateFns.subHours(time, 1);
    const later = dateFns.addHours(time, 1);
    return dateFns.format(earlier, 'xxx') < dateFns.format(later, 'xxx');
  },
  isSpringForwardDay: function(time) {
    if (!dateFns.isValid(time)) { return false; }
    const earlier = dateFns.startOfDay(time);
    const later = dateFns.endOfDay(time);
    return dateFns.format(earlier, 'xxx') > dateFns.format(later, 'xxx');
  },
  updateHidden: function(field) {
    var uniqId = $j(field).data('uniq-id');

    var year = $j("select.year."+uniqId)[0].value
    var month = $j("select.month."+uniqId)[0].value
    var day = $j("select.day."+uniqId)[0].value
    var hour = $j("select.hour."+uniqId)[0].value
    var minute = $j("select.minute."+uniqId)[0].value
    var zone = $j("select.zone."+uniqId)[0].value

    if(month.length == 1) { month = "0"+month; }
    if(day.length == 1) { day = "0"+day; }

    var timeString = year+"-"+month+"-"+day+"T"+hour+":"+minute+":00"
    var time = dateFns.parse(year+'/'+month+'/'+day+' '+hour+':'+minute, "yyyy/MM/dd HH':'mm", new Date());

    if(DatetimeSelectWithDaylightSavingDisambiguation.isAmbiguousFallBackTime(time)) {
      timeString += " "+zone;
      $j("select.zone."+uniqId).show();
    } else {
      $j("select.zone."+uniqId).hide();
      if(DatetimeSelectWithDaylightSavingDisambiguation.isSpringForwardDay(time)) {
        if(hour == '02') { $j("select.hour."+uniqId).val(''); }
        $j("select.hour."+uniqId+" option[value='02']").hide();
      } else {
        $j("select.hour."+uniqId+" option[value='02']").show();
      }
    }

    var hiddenField = $j("input[type='hidden']." + uniqId)[0];
    hiddenField.value = timeString ;
  },
  attachOnChanges: function(uniqId) {
    $j('select.dst_disambiguated.'+uniqId).each(function() {
      DatetimeSelectWithDaylightSavingDisambiguation.updateHidden(this);
      $j(this).on('change', function() {
        DatetimeSelectWithDaylightSavingDisambiguation
          .updateHidden(this);
      });
    });
  }
}

export default DatetimeSelectWithDaylightSavingDisambiguation;
